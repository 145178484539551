import _ from 'lodash'
import { SET_ACTIVE_API_KEY, UNSET_ACTIVE_API_KEY, ADD_API_KEY, DELETE_API_KEY } from '../actions'

const selectSlice = state => _.get(state, 'apiKeys')

export const selectActiveApiKey = state => selectSlice(state).active
export const selectApiKeys = state => _.values(_.omit(selectSlice(state), 'active'))

const defaultKey = {
}

const defaultState = {
  active: defaultKey,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_API_KEY: {
      return {
        ...state,
        active: {
          ..._.omit(action, ['type']),
        },
      }
    }

    case UNSET_ACTIVE_API_KEY: {
      return {
        ...state,
        active: defaultKey,
      }
    }

    case ADD_API_KEY: {
      const oldKey = state[action.id]
      const keyValue = { value: action.value }
      const newKey = _.merge({}, oldKey, action, keyValue)
      return {
        ...state,
        [action.id]: newKey,
      }
    }

    case DELETE_API_KEY: {
      const newState = { ...state }
      delete newState[action.id]
      return newState
    }

    default:
  }
  return state
}
