import React from 'react'

import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'

import {
  compose,
  lifecycle,
} from 'recompose'

const styles = {
  contentContainer: {
    textAlign: 'center',
  },
}

const NakedNotFound = () => (
  <div className="NakedNotFound" style={styles.contentContainer}>
    <Typography variant="headline">There is nothing here!</Typography>
    <Typography variant="caption">We couldn't find any thing.</Typography>
  </div>
)


export default compose(
 withRouter,
 lifecycle({
    componentWillMount() {
      // This is a workaround for improper routing to embedded Redoc container hashes and is required to support navigation directly with
      // pasted links or bookmarks. When Router cannot find the location, this component mounts, upon which we change the window location to docs
      // and include the fragment. This works in conjunction with RedocContentfulContainer which checks for this scenario when mounted and applies
      // the correct URL.
      const { location } = this.props;

      if (location.pathname.includes('/section')) {
        window.location.href = `#/docs/#section/${location.pathname.substring(location.pathname.lastIndexOf('/') +1)}`;
      }

      if (location.pathname.includes('/tag')) {
        window.location.href = `#/docs/#${location.pathname}`;
      }
    }
  })
)(NakedNotFound)
