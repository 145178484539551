export const SET_SETTING = 'SET_SETTING'

export const setSetting = (key, value) => ({
  type: SET_SETTING,
  key,
  value,
})

export const setCodeLanguage = language => setSetting('codeLanguage', language)

export const setSidebarCollapse = collapsed =>
  setSetting('sidebarCollapse', collapsed)
