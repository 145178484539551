import React from 'react'
import ReactDOM from 'react-dom'
import 'babel-polyfill'
import store from './rdx/store'
import ReduxToastr from 'react-redux-toastr'

import ContentSwitch from './components/common/layout/ContentSwitch'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import './vendors'
import './layout/layout.css'
import './main.css'

store.init()

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <div>
        <ContentSwitch />
        <ReduxToastr />
      </div>
    </Provider>
  </HashRouter>,
  document.getElementById('root'),
)

//registerServiceWorker();
