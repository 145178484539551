export const usersConnectors = {
  label: 'Users Connectors',
  value: `# Users Connectors
{
  bcusers {
    accounts {
      emails {
        value
      }
      connector {
        provider {
          displayValue
        }
      }
    }
  }
}
`,
}
