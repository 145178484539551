import { createStore, applyMiddleware, compose } from 'redux'
import persistState from 'redux-localstorage'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './state'
import rootSaga from './sagas'
import { applyApiKey } from './actions'
import { selectActiveApiKey } from './selectors'

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  /* preloadedState, */
  composeEnhancers(applyMiddleware(...middleware), persistState(['apiKeys', 'settings', 'gql'])),
)

sagaMiddleware.run(rootSaga)

store.init = () => {
  const apiKey = selectActiveApiKey(store.getState())

  if (apiKey && apiKey.value) {
    store.dispatch(applyApiKey(apiKey.value))
  }
}

export default store
