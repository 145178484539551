import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as contentful from 'contentful'

import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose'

import ReactMarkdown from 'react-markdown'
import NakedNoToken from './NakedNoToken'

const space = 'ebuqgd6z6b2b'
const accessToken = 'ae258bf5262d499f221801c84eea6177d3a12b716ab6607485ad0b754b59b8a2'

const client = contentful.createClient({
  space,
  accessToken,
})

const Loading = () => (
  <div className="Loading">
    <h1>Loading</h1>
  </div>
)

const ContentfulContainer = ({ contentKey, contentPayload }) => (
  <div className="ContentfulContainer">
    <ReactMarkdown children={contentPayload.value.content} />
  </div>
)

export default compose(
  setPropTypes({
    contentKey: PropTypes.string.isRequired,
  }),
  withState('contentPayload', 'setContent', {
    loading: false,
    initialized: false,
    contentKey: null,
    value: null,
  }),
  withHandlers({
    fetchContent: ({ contentPayload, setContent, contentKey }) => () => {
      if (!contentPayload.loading) {
        setContent({
          ...contentPayload,
          contentKey,
          loading: true,
        })
        client
          .getEntries({
            content_type: 'contentPage',
            query: contentKey,
          })
          .then(res =>
            setContent({
              loading: false,
              initialized: true,
              key: contentKey,
              value: _.get(
                res.items.filter(entity => entity.fields.contentKey === contentKey),
                '[0].fields',
              ),
            }),
          )
          .catch(err => {
            console.error(err)
          })
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchContent()
    },
    componentWillReceiveProps(nextProps) {
      if (this.props.contentKey !== nextProps.contentKey) {
        this.props.fetchContent()
      }
    },
  }),
  branch(({ contentPayload }) => contentPayload.loading, renderComponent(Loading)),
  branch(({ contentPayload }) => _.isNil(contentPayload.value), renderComponent(NakedNoToken)),
)(ContentfulContainer)
