import React from 'react'
import { selectActiveApiKey } from '../../rdx/selectors'
import { graphQLFetcherByQuery } from './graphQLFetcher'

import { compose, onlyUpdateForKeys } from 'recompose'
import { connect } from 'react-redux'

import { Voyager } from 'graphql-voyager'

const MuiGraphPage = ({ apiKey }) => (
  <div className="MuiGraphPage">
    <div style={{ height: '90vh' }}>
      <Voyager
        introspection={graphQLFetcherByQuery(apiKey)}
        workerURI={process.env.PUBLIC_URL + '/assets/js/voyager.worker.js'}
      />
    </div>
  </div>
)

export default compose(
  connect(state => ({
    apiKey: selectActiveApiKey(state).value,
  })),
  onlyUpdateForKeys({
    propKeys: ['apiKey'],
  }),
)(MuiGraphPage)
