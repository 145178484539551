import React from 'react'
import {
  selectGqlQuery,
  selectSettingCodeLanguage,
  selectActiveApiKey,
} from '../../rdx/selectors'
import bcxEnv from '../../bcxEnv'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toastr } from 'react-redux-toastr'

import { compose, withState } from 'recompose'
import { connect } from 'react-redux'

import { Button } from 'primereact/components/button/Button'

function toCurl(url, query) {
  return `
curl -XPOST '${url}' \\
  -H 'Content-Type: application/json' \\
  -H 'Authorization: {apiKey}' \\
  -d '
{
  "query": "${query}"
}'
`
}

function toJs(url, query) {
  return `
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

fetch('${url}', {
  method: 'post',
  body: JSON.stringify({
    query: '${query}'
  }),
  headers: {
    'Content-Type': 'application/json',
    Authorization: '{apiKey}'
  }
}).then(response => {
  return response.json()
}).then(({ data }) => {
  console.log(data)
})
.catch(e => {
  console.error('Failed to execute query', e)
})
`
}

function toPython(url, query) {
  return `
# http://docs.python-requests.org/en/latest/

import requests
url = '${url}'
data = '''{
  "query": "${query}"
}'''
headers = {
  "Content-Type": "application/json",
  "Authorization": "{apiKey}"
}
data = requests.post(url, data=data, headers=headers).json()
print data
`
}

function toSnippet(codeLanguage, query, apiKey) {
  const url = `${bcxEnv.baseUrl}/api/graphql`
  const strippedQuery = query
    .replace(/#[^\n]*(?:\n|$)/g, '')
    .replace(/\s+/g, ' ')
    .replace(/["]/g, '\\"')
  switch (codeLanguage) {
    case 'js':
      return toJs(url, strippedQuery, apiKey)
    case 'python':
      return toPython(url, strippedQuery, apiKey)
    case 'curl':
    default:
  }
  return toCurl(url, strippedQuery, apiKey)
}

const GqlSnippets = ({ query, codeLanguage, apiKey }) => {
  const snippet = toSnippet(codeLanguage, query, apiKey)
  return (
    <div className="GqlSnippets">
      <div style={{ textAlign: 'right' }}>
        <CopyToClipboard
          text={snippet}
          onCopy={() => toastr.success('Copied code snippet')}
        >
          <Button label="Copy Snippet" icon="fa fa-copy" />
        </CopyToClipboard>
        <CopyToClipboard
          text={query}
          onCopy={() => toastr.success('Copied query snippet')}
        >
          <Button label="Copy Query" icon="fa fa-copy" />
        </CopyToClipboard>
      </div>
      <pre>
        <code>{snippet}</code>
      </pre>
    </div>
  )
}

export default compose(
  withState('activeIndex', 'setActiveIndex', 0),
  connect(state => ({
    query: selectGqlQuery(state),
    codeLanguage: selectSettingCodeLanguage(state),
    apiKey: selectActiveApiKey(state).value,
  })),
)(GqlSnippets)
