import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { applyApiKey, deleteApiKey, validateApiKey, unsetActiveApiKey } from '../../rdx/actions'
import { selectActiveApiKey, selectApiKeys } from '../../rdx/selectors'

import { compose, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TextField from '@material-ui/core/TextField'
import RowActionsButton from '../common/lists/RowActionsButton'

function getConfirmMessage(apiKey) {
  return `
Are you sure? Delete ${apiKey.title}?

Note: This only deletes the key from your browser. It does NOT delete it from the BetterCloud application.

The key will still be active, but you will not be able to use it in the developer portal unless you add it again.
`
}

const rowOptions = (apiKey, setActiveKey, validateKey, deleteKey, deleteActiveKey) => {
  const options = []
  if (!apiKey.isActive) {
    options.push({ label: 'Set As Active', handler: () => setActiveKey(apiKey.value) })
  }

  return [
    ...options,
    { label: 'Show Value', handler: () => window.alert(apiKey.value) },
    { label: 'Validate', handler: () => validateKey(apiKey.value) },
    {
      label: 'Delete',
      handler: () => {
        if (window.confirm(getConfirmMessage(apiKey))) {
          deleteKey(apiKey.id)
          if (apiKey.isActive) {
            deleteActiveKey()
          }
        }
      },
    },
  ]
}

const ManageTokens = ({
  apiKeys,
  addNewKey,
  deleteKey,
  newKey,
  setActiveKey,
  setNewKey,
  validateKey,
  deleteActiveKey,
}) => (
  <div className="ManageTokens">
    <h1>Add New Api Key:</h1>
    <form onSubmit={e => addNewKey(e)}>
      <TextField
        className="platformApiToken"
        value={newKey}
        onChange={e => setNewKey(e.target.value)}
        placeholder="Paste new api key here and hit enter, e.g. 790c3d35b46c4e788a202a9c82f864cc"
        style={{ width: '100%' }}
      />
    </form>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Valid</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {apiKeys.map(apiKey => (
          <TableRow key={apiKey.id}>
            <TableCell>
              {apiKey.title}&nbsp;&nbsp;
              {apiKey.isActive ? <em>(ACTIVE)</em> : ''}
            </TableCell>
            <TableCell>{apiKey.meta.description}</TableCell>
            <TableCell>
              {apiKey.meta.valid ? `As of ${moment(apiKey.meta.valid).fromNow()}` : 'FALSE'}
            </TableCell>
            <TableCell>
              <RowActionsButton
                options={rowOptions(apiKey, setActiveKey, validateKey, deleteKey, deleteActiveKey)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)

export default compose(
  withState('newKey', 'setNewKey', ''),
  connect(
    state => {
      const active = _.defaultTo(selectActiveApiKey(state), {})
      const apiKeys = selectApiKeys(state)
      apiKeys.forEach(apiKey => (apiKey.isActive = apiKey.id === active.id))
      return {
        active,
        apiKeys: _.sortBy(apiKeys, 'isActive').reverse(),
      }
    },
    dispatch => ({
      setActiveKey: newKey => dispatch(applyApiKey(newKey)),
      deleteActiveKey: () => dispatch(unsetActiveApiKey()),
      deleteKey: id => dispatch(deleteApiKey(id)),
      validateKey: keyValue => dispatch(validateApiKey(keyValue)),
    }),
  ),
  withHandlers({
    addNewKey: ({ setActiveKey, newKey, setNewKey }) => e => {
      e.preventDefault()
      setActiveKey(newKey)
      setNewKey('')
    },
  }),
)(ManageTokens)
