export const APPLY_API_KEY = 'APPLY_API_KEY'
export const SET_ACTIVE_API_KEY = 'SET_ACTIVE_API_KEY'
export const UNSET_ACTIVE_API_KEY = 'UNSET_ACTIVE_API_KEY'
export const ADD_API_KEY = 'ADD_API_KEY'
export const DELETE_API_KEY = 'DELETE_API_KEY'
export const VALIDATE_API_KEY = 'VALIDATE_API_KEY'

export const applyApiKey = newKey => ({
  type: APPLY_API_KEY,
  newKey,
})

export const setActiveApiKey = (id, value, title, meta = {}) => ({
  type: SET_ACTIVE_API_KEY,
  id,
  value,
  title,
  meta,
})

export const unsetActiveApiKey = () => ({
  type: SET_ACTIVE_API_KEY,
})

export const validateApiKey = value => ({
  type: VALIDATE_API_KEY,
  value,
})

export const addApiKey = (id, value, title, meta = { valid: true }) => ({
  type: ADD_API_KEY,
  id,
  value,
  title,
  meta,
})

export const deleteApiKey = (id, value, title, meta = {}) => ({
  type: DELETE_API_KEY,
  id,
})
