import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'
import MaterialLayout from './components/common/layout/MaterialLayout'
import ContentfulPage from './components/common/ContentfulPage'

import MuiBuffersFormPage from './components/automation/buffers/MuiBuffersFormPage'

import DeveloperSettings from './components/settings/DeveloperSettings'
import ManageTokens from './components/settings/ManageTokens'
import ManageLanguage from './components/settings/ManageLanguage'

import MuiExplorePage from './components/explore/MuiExplorePage'
import MuiGraphPage from './components/explore/MuiGraphPage'

import NakedNotFound from './components/common/NakedNotFound'
import RedocContentfulContainer from './components/common/RedocContentfulContainer';

export const MuiAppRoutes = () => (
  <Switch>
    <Route exact path="/docs" render={() => <RedocContentfulContainer contentKey="docs"/>}/>
    <MaterialLayout>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/p/quickstart"/>} />

        <Route exact path="/buffers" component={MuiBuffersFormPage} />

        <Route exact path="/settings" component={DeveloperSettings} />
        <Route exact path="/settings/tokens" component={ManageTokens} />
        <Route exact path="/settings/codeLanguage" component={ManageLanguage} />

        <Route exact path="/reporting/explore" component={MuiExplorePage} />
        <Route exact path="/reporting/graph" component={MuiGraphPage} />

        <Route exact path="/p/:contentKey" component={ContentfulPage} />

        <Route component={NakedNotFound} />
      </Switch>
    </MaterialLayout>
  </Switch>
)