const handleHash = url => (withHash = false) => (withHash ? `/#${url}` : url)
const handleHashWithId = url => (id, withHash = false) =>
  withHash ? `/#${url}/${id}` : `${url}/${id}`

export default {
  home: handleHash('/'),
  content: handleHashWithId('/p'),
  docs: {
    home: handleHash('/docs'),
  },
  buffers: {
    home: handleHash('/buffers'),
    create: handleHash('/buffers/new'),
    edit: handleHashWithId('/buffers/edit'),
    history: handleHashWithId('/buffers/history'),
  },
  reporting: {
    explore: handleHash('/reporting/explore'),
    graph: handleHash('/reporting/graph'),
  },
  settings: {
    home: handleHash('/settings'),
    tokens: handleHash('/settings/tokens'),
    language: handleHash('/settings/codeLanguage'),
  },
}
