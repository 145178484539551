import React from 'react'
import urls from '../../urls'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'

const DeveloperSettings = () => (
  <div className="DeveloperSettings">
    <h1>Developer Settings</h1>
    <Grid container spacing={16}>
      <Grid item md={6}>
        <Card>
          <CardActionArea onClick={() => (window.location = urls.settings.tokens(true))}>
            <CardMedia style={{ height: '200px' }} image="/assets/layout/images/tokens.jpeg" />
            <CardContent>
              <h2>Manage Tokens</h2>
              Manage the BetterCloud API tokens tied to your individual connection to the BetterCloud Developer Platform.
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardActionArea onClick={() => (window.location = urls.settings.language(true))}>
            <CardMedia
              style={{ height: '200px' }}
              image="/assets/layout/images/settingsCodeLanguage.jpeg"
            />
            <CardContent>
              <h2>Manage Coding Language</h2>
              Several parts of the BetterCloud Developer Platform automatically generate code snippets for use in coding external automations. Choose whether these snippets are presented in Curl, Python, or NodeJS.
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  </div>
)

export default DeveloperSettings
