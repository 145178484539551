export const publicFiles = {
  label: 'Public Files',
  value: `# Public Files
  {
  assets(filter: {directory: false, maxVisibility: {eq: PUBLIC}}, pagination: {offset: 0, limit: 10}) {
    id
    displayValue
    externalUrl
    fileSizeBytes
    mimeType
    lastModifiedDate
    owner {
      __typename
      ... on SaasUser {
        displayValue
      }
      ... on Group {
        displayValue
      }
    }
    permissions {
      targetId
      targetType
      target {
        ... on SaasUser {
          displayValue
        }
        ... on Group {
          displayValue
        }
      }
    }
  }
}`,
}