import _ from 'lodash'
import {
  FETCH_EMITTER_HISTORY_BY_ID,
  FETCH_EMITTER_HISTORY_BY_ID_SUCCESS,
  FETCH_EMITTER_HISTORY_BY_ID_ERROR,
} from '../actions'

const selectSlice = state => _.get(state, 'history')

const _selectHistorySlice = (stateSlice, emitterId) => {
  const emitterSlice = stateSlice[emitterId]
  if (_.isNil(emitterSlice)) {
    return defaultEmitterState()
  }
  return emitterSlice
}

export const selectHistorySlice = (state, emitterId) =>
  _selectHistorySlice(selectSlice(state), emitterId)

const defaultState = {}

const defaultEmitterState = () => ({
  loading: false,
  initialized: false,
  content: [],
  page: 0,
  limit: 0,
  total: 0,
  error: null,
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_EMITTER_HISTORY_BY_ID: {
      const { emitterId } = action
      const emitterSlice = _selectHistorySlice(state, emitterId)
      return {
        ...state,
        [emitterId]: {
          ...emitterSlice,
          loading: true,
        },
      }
    }

    case FETCH_EMITTER_HISTORY_BY_ID_SUCCESS: {
      const { emitterId, content, page, limit, total } = action
      const emitterSlice = _selectHistorySlice(state, emitterId)
      return {
        ...state,
        [emitterId]: {
          ...emitterSlice,
          loading: false,
          initialized: true,
          content,
          page,
          limit,
          total,
          error: null,
        },
      }
    }

    case FETCH_EMITTER_HISTORY_BY_ID_ERROR: {
      const { emitterId, error, page, limit } = action
      const emitterSlice = _selectHistorySlice(state, emitterId)
      return {
        ...state,
        [emitterId]: {
          ...emitterSlice,
          loading: false,
          initialized: true,
          content: [],
          page,
          limit,
          total: (page + 1) * limit,
          error,
        },
      }
    }

    default:
  }
  return state
}
