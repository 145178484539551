import {
  BUFFERS,
  SUBSCRIPTIONS,
  WEBHOOKHISTORY,
} from '../../entities'
import { entityGenerator } from './base'

let actions = entityGenerator(BUFFERS, '/api/v1/emitters', 'type==POLLING')

export const saveBuffer = actions.saveEntity
export const fetchBuffer = actions.fetchEntity
export const fetchBuffers = actions.fetchEntities
export const deleteBuffer = actions.deleteEntity

actions = entityGenerator(SUBSCRIPTIONS, '/api/v1/subscriptions')
export const saveSubscription = actions.saveEntity
export const deleteSubscription = actions.deleteEntity

actions = entityGenerator(WEBHOOKHISTORY, '/api/v1/emitters/executions')
export const fetchEmitterHistory = actions.fetchEntities
