import _ from 'lodash'

export function getBody(rawBody) {
  let body = rawBody
  try {
    if (_.isString(body)) {
      body = JSON.parse(rawBody)
    }
    if (_.isObject(body)) {
      body = JSON.stringify(body, null, 2)
    }
  } catch (e) {}
  return body
}
