import { combineReducers } from 'redux'
import { reducer as toastr } from 'react-redux-toastr'
import apiKeys from './apiKeys'
import emitters from './emitters'
import entities from './entities'
import { reducer as formReducer } from 'redux-form'
import gql from './gql'
import history from './history'
import settings from './settings'

export default combineReducers({
  apiKeys,
  emitters,
  entities,
  form: formReducer,
  gql,
  history,
  settings,
  toastr,
})

export * from './apiKeys'
export * from './emitters'
export * from './entities'
export * from './gql'
export * from './history'
export * from './settings'
