import React from 'react'
import _ from 'lodash'
import uuid from 'uuid'
import { selectDefaultBuffer } from '../../../rdx/selectors'
import { saveSubscription } from '../../../rdx/actions'
import { SUBSCRIPTION_SOURCES } from '../../../entities'

import { branch, compose, renderNothing, withProps } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MuiBuffersTestForm from './MuiBuffersTestForm'

const styles = {
  activeCard: {
    backgroundColor: '#333',
  },
  media: {
    height: 140,
  },
}

const SubscriptionButton = ({ source, toggleSourceSubscription }) => {
  const { subscription } = source
  const active = (!_.isNil(subscription) && subscription.enabled) || source.bufferProps.enabled
  const variant = active ? 'contained' : 'outlined'
  const content = active ? 'Active' : 'Disabled'
  return (
    <Button
      className="SubscriptionButton"
      color="primary"
      disabled={source.props.disabled}
      variant={variant}
      style={{ width: '100%' }}
      onClick={() => toggleSourceSubscription(source)}
    >
      {content}
    </Button>
  )
}

const MuiBuffersFormPage = ({ model, classes, sources, toggleSourceSubscription }) => (
  <div className="MuiBuffersFormPage">
    <h1>API Buffers</h1>
    <Table>
      <TableBody>
        {sources.map(source => (
          <TableRow key={source.id}>
            <TableCell>
              <SubscriptionButton
                source={source}
                toggleSourceSubscription={toggleSourceSubscription}
              />
            </TableCell>
            <TableCell>{source.key}</TableCell>
            <TableCell>{source.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <h1 style={{ margin: '1em 0' }}>Testing</h1>
    <div style={{ padding: '1em' }}>
      <MuiBuffersTestForm emitter={model} />
    </div>
  </div>
)

export default compose(
  withStyles(styles),
  connect(
    state => ({ model: _.cloneDeep(selectDefaultBuffer(state)) }),
    dispatch => ({
      toggleSourceSubscription: source => {
        let { subscription, destination } = source
        if (!_.isNil(subscription)) {
          subscription = _.cloneDeep(subscription)
          subscription.enabled = !subscription.enabled
        } else {
          subscription = {
            destId: destination.id,
            destType: destination.type,
            srcId: source.id,
            enabled: true,
            id: uuid(),
          }
        }
        dispatch(saveSubscription(subscription))
      },
    }),
  ),
  branch(({ model }) => _.isNil(model), renderNothing),
  withProps(({ model }) => ({
    sources: SUBSCRIPTION_SOURCES.map(s => {
      const source = _.cloneDeep(s)
      source.subscription = model.subscriptions[s.id]
      source.destination = model
      return source
    }),
  })),
)(MuiBuffersFormPage)
