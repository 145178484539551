import store from '../rdx/store'
import _ from 'lodash'
import axios from 'axios'
import uuid from 'uuid'
import { saveBuffer, fetchBuffers } from '../rdx/actions'
import bcxEnv from '../bcxEnv'

const { baseUrl } = bcxEnv

export default {
  fetchOrCreateDefaultBuffer() {
    axios.get(`${baseUrl}/api/v1/emitters?limit=1&query=type==POLLING`).then(res => {
      const buffers = res.data.content
      let defaultBuffer = null
      for (let i = 0; i < buffers.length; i++) {
        const buffer = buffers[i]
        const isBuffer = buffer.type === 'POLLING'
        if (isBuffer && buffer.meta.isDefault) {
          defaultBuffer = buffer
        }
      }
      if (_.isNil(defaultBuffer)) {
        const newDefaultBuffer = {
          id: uuid(),
          title: 'Default Event Stream Buffer',
          description:
            'Created by BetterCloud. This Event Stream Buffer is to be used for all buffer subscriptions that do not require transformations.',
          type: 'POLLING',
          metaVersion: 'v1',
          meta: {
            isDefault: true,
          },
        }
        store.dispatch(saveBuffer(newDefaultBuffer))
      } else {
        store.dispatch(fetchBuffers())
      }
    })
  },
}
