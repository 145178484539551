import _ from 'lodash'
import { SET_SETTING } from '../../rdx/actions'

const selectSlice = state => _.get(state, 'settings')

export const selectSettings = state => selectSlice(state)

export const selectSettingCodeLanguage = state =>
  selectSlice(state).codeLanguage

export const selectSettingSidebarCollapse = state =>
  selectSlice(state).sidebarCollapse

const defaultState = {
  codeLanguage: 'curl',
  sidebarCollapse: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SETTING: {
      return {
        ...state,
        [action.key]: action.value,
      }
    }

    default:
  }
  return state
}
