import React from 'react'

import { Route, Switch } from 'react-router-dom'
import { MuiAppRoutes } from './MuiAppRoutes'


const MuiApp = () => (
  <div className="MuiApp">
      <Switch>
        <Route component={MuiAppRoutes}/>
      </Switch>
  </div>
)

export default MuiApp
