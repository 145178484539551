import React from 'react'

import { compose, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'

import ContentfulContainer from './ContentfulContainer'

const ContentfulPage = ({ contentKey }) => (
  <div className="ContentfulPage">
    <ContentfulContainer contentKey={contentKey} />
  </div>
)

export default compose(
  withRouter,
  withProps(({ match }) => ({
    contentKey: match.params.contentKey,
  })),
)(ContentfulPage)
