export const FETCH_EMITTER_HISTORY_BY_ID = 'FETCH_EMITTER_HISTORY_BY_ID'
export const FETCH_EMITTER_HISTORY_BY_ID_SUCCESS = 'FETCH_EMITTER_HISTORY_BY_ID_SUCCESS'
export const FETCH_EMITTER_HISTORY_BY_ID_ERROR = 'FETCH_EMITTER_HISTORY_BY_ID_ERROR'

export const fetchEmitterHistoryById = (emitterId, page = 0, limit = 10) => ({
  type: FETCH_EMITTER_HISTORY_BY_ID,
  emitterId,
  page,
  limit,
})

export const handleFetchEmitterHistoryByIdSuccess = (emitterId, content, page, limit, total) => ({
  type: FETCH_EMITTER_HISTORY_BY_ID_SUCCESS,
  emitterId,
  content,
  page,
  limit,
  total,
})

export const handleFetchEmitterHistoryByIdError = (emitterId, error, page, limit) => ({
  type: FETCH_EMITTER_HISTORY_BY_ID_ERROR,
  emitterId,
  error,
  page,
  limit,
})
