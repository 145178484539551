import React from 'react'
import _ from 'lodash'
import { selectActiveApiKey } from '../../../rdx/selectors'

import { withRouter } from 'react-router-dom'
import { branch, compose, renderComponent } from 'recompose'
import { connect } from 'react-redux'

import MuiApp from '../../../MuiApp'
import MuiAnonApp from '../../../MuiAnonApp'
import { Route, Switch } from 'react-router-dom'

const ContentSwitch = ({ activeToken }) => (
  <div className="ContentSwitch">
    <Switch>
      <Route component={MuiApp} />
    </Switch>
  </div>
)

function isValidToken(activeToken) {
  return (
    _.isNil(activeToken) ||
    _.get(activeToken, 'meta.isAnon') ||
    _.isNil(activeToken.id) ||
    !_.get(activeToken, 'meta.valid')
  )
}

export default compose(
  withRouter,
  connect(state => ({
    activeToken: selectActiveApiKey(state),
  })),
  branch(({ activeToken }) => isValidToken(activeToken), renderComponent(MuiAnonApp)),
)(ContentSwitch)
