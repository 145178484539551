import React from 'react'
import urls from '../../../urls'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { Link } from 'react-router-dom'

import logo from '../../../layout/images/logo-white-small.png'

const styles = {
  grow: {
    flexGrow: 1,
  },
  headerChip: {
    backgroundColor: '#768ca0',
    padding: '0 7px',
    borderRadius: '3px',
    display: 'inline-block',
    color: 'white',
  },
  navLink: {
    color: '#3a9cff',
    marginRight: '2em',
  },
  newEmitterBtn: {
    backgroundColor: '#00ab80',
    color: 'white',
  },
}

const NavBar = ({ onClickNew }) => (
  <div className="NavBar">
    <AppBar position="static">
      <Toolbar>
        <a href="https://app.bettercloud.com">
          <img src={logo} alt="" />
        </a>
        <div style={styles.grow}>
          <Link to={urls.home()}>
            <Typography color="inherit" style={styles.headerChip}>
              Developer
            </Typography>
          </Link>
        </div>
        <Link to={urls.docs.home()} style={styles.navLink} target="_blank">
          Documentation
        </Link>
      </Toolbar>
    </AppBar>
  </div>
)

export default NavBar
