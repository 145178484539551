import _ from 'lodash'
import uuid from 'uuid'
import { call, put, select, takeEvery }  from 'redux-saga/effects'
import {
  APPLY_API_KEY,
  DELETE_ENTITY,
  EXECUTE_EMITTER_TEST,
  EXECUTE_POLLING_TEST,
  FETCH_EMITTER_HISTORY_BY_ID,
  FETCH_ENTITY,
  FETCH_ENTITIES,
  SAVE_ENTITY,
  VALIDATE_API_KEY,
  addApiKey,
  executeEmitterTestSuccess,
  handleFetchEmitterHistoryByIdSuccess,
  handleFetchEmitterHistoryByIdError,
  handleFetchEntitiesFailure,
  handleFetchEntitiesSuccess,
  handleFetchEntityFailure,
  handleFetchEntitySuccess,
  handleSaveSuccess,
  handleDeleteSuccess,
  saveSubscription,
  setActiveApiKey,
} from '../actions'

import { selectActiveApiKey } from '../selectors'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import bcxEnv from '../../bcxEnv'
import webhookMapper from './mappers/webhookMapper'
import bufferEmitterService from '../../service/bufferEmitterService'

const { baseUrl, fsOrg } = bcxEnv

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

function* setDefaultHeaders() {
  if (_.isNil(axios.defaults.headers.common['Authorization'])) {
    const state =  yield select();
    const apiKey = selectActiveApiKey(state)
    if (!_.isNil(apiKey)) {
      axios.defaults.headers.common['Authorization'] = apiKey.value
    }
  }
}

function* applyApiKey(action) {
  try {
    const response = yield call(axios.get, `${baseUrl}/api/v1/tokens/lookup`, {
      headers: { Authorization: action.newKey },
    })
    const { id, name, tenantId, createdAt, description } = response.data
    initFullStory()
    initPendo()
    const meta = { tenantId, createdAt, description, valid: new Date().getTime() }
    axios.defaults.headers.common['Authorization'] = action.newKey
    yield put(setActiveApiKey(id, action.newKey, name, meta))
    yield put(addApiKey(id, action.newKey, name, meta))
    bufferEmitterService.fetchOrCreateDefaultBuffer()
  } catch (e) {
    console.error(e)
    toastr.error(`Failed validate API Key: "${e.message}"`)
  }
}

function* validateApiKey(action) {
  try {
    const response = yield call(axios.get, `${baseUrl}/api/v1/tokens/lookup`, {
      headers: { Authorization: action.value },
    })
    const { id, name, tenantId, createdAt, description } = response.data
    initFullStory()
    initPendo()
    const meta = { tenantId, createdAt, description, valid: new Date().getTime() }
    yield put(addApiKey(id, action.newKey, name, meta))
    toastr.success('API key is valid')
  } catch (e) {
    console.error(e)
    toastr.error(`Failed validate API Key: "${e.message}"`)
  }
}

function* exeFetchEntity(action) {
  try {
    yield call(setDefaultHeaders)
    const response = yield call(axios.get, `${baseUrl}${action.path}/${action.id}`)
    const entity = response.data
    if (entity.type === 'WEBHOOK' || entity.type === 'SCRIPTING') {
      webhookMapper.inbound(entity)
    }
    yield put(handleFetchEntitySuccess(action.entityKey, action.id, entity))
  } catch (e) {
    console.error(e)
    toastr.error(`Failed to fetch entity ${action.path}: "${e.message}"`)
    yield put(handleFetchEntityFailure(e, action.entityKey))
  }
}

function* exeFetchEntities(action) {
  try {
    yield call(setDefaultHeaders)
    const { page, limit } = action.paging
    const response = yield call(axios.get, `${baseUrl}${action.path}?limit=${limit ? limit: 10}&page=${page ? page : 0}${action.query ? `&query=${action.query}` : ''}`)
    const byIds = {}
    const ids = response.data.content.map(def => {
      byIds[def.id] = def
      if (def.type === 'WEBHOOK' || def.type === 'SCRIPTING') {
        webhookMapper.inbound(def)
      }
      return def.id
    })
    const pagination = {
      count: response.data.totalElements,
      size: response.data.size,
      page: response.data.number,
      pages: response.data.totalPages,
    }
    yield put(handleFetchEntitiesSuccess(action.entityKey, byIds, ids, pagination))
  } catch (e) {
    console.error(e)
    toastr.error(`Failed to fetch entities ${action.slug}: "${e.message}"`)
    yield put(handleFetchEntitiesFailure(e, action.entityKey))
  }
}


function* exeFetchEntityHistory(action) {
  const { emitterId, page, limit } = action
  try {
    yield call(setDefaultHeaders)
    const response = yield call(
      axios.get,
      `${baseUrl}/api/v1/emitters/${emitterId}/executions?page=${page}&limit=${limit}`,
    )
    const content = response.data.content
    const total = response.data.totalElements
    yield put(handleFetchEmitterHistoryByIdSuccess(emitterId, content, page, limit, total))
  } catch (e) {
    console.error(e)
    toastr.error(`Failed to fetch emitter history: "${e.message}"`)
    yield put(handleFetchEmitterHistoryByIdError(emitterId, e.message, page, limit))
  }
}

function* exeSave(action) {
  try {
    const { entityKey, path, entity } = action
    if (entity.type === 'WEBHOOK' || entity.type === 'SCRIPTING') {
      webhookMapper.outbound(entity)
    }
    let url = `${baseUrl}${path}`

    const sources = _.get(entity, 'meta.sources')
    if (_.isPlainObject(entity.subscriptions) && _.isPlainObject(sources)) {
      const keys = _.keys(sources)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const value = sources[key]
        const sub = entity.subscriptions[key]
        if (!_.isNil(sub)) {
          yield put(
            saveSubscription(
              {
                ...sub,
                enabled: value,
              },
              true,
            ),
          )
        } else if (value) {
          yield put(
            saveSubscription(
              {
                destId: entity.id,
                destType: entity.type,
                srcId: key,
                enabled: true,
                id: uuid(),
              },
              true,
            ),
          )
        }
      }
    }

    const res = yield call(axios.post, url, entity)
    const resultEntity = res.data

    if (!action.silent) {
      toastr.success('Saved')
    }
    if (resultEntity.type === 'WEBHOOK' || resultEntity.type === 'SCRIPTING') {
      webhookMapper.inbound(resultEntity)
    }
    yield put(handleSaveSuccess(entityKey, resultEntity))
  } catch (e) {
    console.error(e)
    toastr.error(`Failed to save ${action.slug}: "${e.message}"`)
  }
}

function* exeDelete(action) {
  try {
    yield call(axios.delete, `${baseUrl}${action.path}/${action.id}`)
    toastr.success('Deleted')
    yield put(handleDeleteSuccess(action.entityKey, action.id))
  } catch (e) {
    console.error(e)
    toastr.error(`Failed to delete ${action.slug}: "${e.message}"`)
  }
}

function* exeTestEmitter(action) {
  try {
    const { emitterId, payload, exeType } = action
    const res = yield call(axios, {
      method: 'POST',
      url: `${baseUrl}/api/v1/emitters/${emitterId}/executions?type=${exeType}`,
      data: payload,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
    const { request, response } = res.data
    if (_.isNil(request.body)) {
      request.body = payload
    }
    yield put(executeEmitterTestSuccess(emitterId, request, response))
  } catch (e) {
    console.error(e)
    toastr.error(`Test emitter failed: "${e.message}"`)
  }
}

function* exeTestPolling(action) {
  try {
    const { emitterId, payload } = action
    let url =
      `${baseUrl}/api/v1/events/buffers/test/${emitterId}?` +
      Object.keys(payload)
        .map(key => `${key}=${payload[key]}`)
        .join('&')
    const res = yield call(axios, {
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
    const { request, response } = res.data
    request.url = request.url.replace(/^.*\/api/, `${baseUrl}/api`)
    yield put(executeEmitterTestSuccess(emitterId, request, response))
  } catch (e) {
    console.error(e)
    toastr.error(`Test emitter failed: "${e.message}"`)
  }
}

const initFullStory = () => {
  window['_fs_debug'] = false;
  window['_fs_host'] = 'fullstory.com';
  window['_fs_org'] = fsOrg;
  window['_fs_namespace'] = 'FS';
  (function(m,n,e,t,l,o,g,y){
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    // eslint-disable-next-line no-undef
    o=n.createElement(t);o.async=1;o.src='https://'+_fs_host+'/s/fs.js';
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
  })(window,document,window['_fs_namespace'],'script','user');
}

const initPendo = () => {
  (function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
      v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    // initialize pendo with anonymous user information
    window.pendo.initialize();
  })('4d4273c4-afbe-4a93-51c7-1fd29452cd86');
}

export default function* rootSaga() {
  yield takeEvery(APPLY_API_KEY, applyApiKey)
  yield takeEvery(DELETE_ENTITY, exeDelete)
  yield takeEvery(EXECUTE_EMITTER_TEST, exeTestEmitter)
  yield takeEvery(EXECUTE_POLLING_TEST, exeTestPolling)
  yield takeEvery(FETCH_EMITTER_HISTORY_BY_ID, exeFetchEntityHistory)
  yield takeEvery(FETCH_ENTITIES, exeFetchEntities)
  yield takeEvery(FETCH_ENTITY, exeFetchEntity)
  yield takeEvery(SAVE_ENTITY, exeSave)
  yield takeEvery(VALIDATE_API_KEY, validateApiKey)
}
