import bcxEnv from '../../bcxEnv'

const { baseUrl } = bcxEnv

const fetcher = (apiKey, graphQLParams) => {
  return fetch(`${baseUrl}/api/graphql`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: apiKey,
    },
    body: JSON.stringify(graphQLParams),
  }).then(response => response.json())
}

const graphQLFetcher = apiKey => params => fetcher(apiKey, params)

export const graphQLFetcherByQuery = apiKey => query =>
  fetcher(apiKey, { query })

export default graphQLFetcher
