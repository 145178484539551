import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as contentful from 'contentful'
import { RedocStandalone } from 'redoc'
import { withRouter } from 'react-router-dom'

import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose'

import NakedNoToken from './NakedNoToken'

const space = 'ebuqgd6z6b2b'
const accessToken = 'ae258bf5262d499f221801c84eea6177d3a12b716ab6607485ad0b754b59b8a2'

const client = contentful.createClient({
  space,
  accessToken,
})

const Loading = () => (
  <div className="Loading">
    <h1>Loading</h1>
  </div>
)

const RedocContentfulContainer = ({ swaggerContentPayload }) => (
  <div className="ContentfulContainer">
    <RedocStandalone spec={swaggerContentPayload.value}/>
  </div>
)

export default compose(
  setPropTypes({
    contentKey: PropTypes.string.isRequired,
  }),
  withState('contentPayload', 'setContent', {
    loading: false,
    initialized: false,
    contentKey: null,
    value: null,
  }),
  withState('swaggerContentPayload', 'setSwaggerContent', {
    loading: false,
    initialized: false,
    value: null,
  }),
  withRouter,
  withHandlers({
    fetchContent: ({ contentPayload, setContent, contentKey }) => () => {
      if (!contentPayload.loading) {
        setContent({
          ...contentPayload,
          contentKey,
          loading: true,
        })
        client
          .getEntries({
            content_type: 'contentPage',
            query: contentKey,
          })
          .then(res =>
            setContent({
              loading: false,
              initialized: true,
              key: contentKey,
              value: _.get(
                res.items.filter(entity => entity.fields.contentKey === contentKey),
                '[0].fields',
              ),
            }),
          )
      }
    },
    fetchSwaggerContent: ({ contentPayload, swaggerContentPayload, setSwaggerContent }) => () => {
      if (!swaggerContentPayload.loading) {
        setSwaggerContent({
          ...swaggerContentPayload,
          loading: true,
        })
        

        fetch('/assets/docs/BetterCloudAPI.OpenApi3.json')
        .then(res => res.json())
        .then(data => {
          setSwaggerContent({
            loading: false,
            initialized: true,
            value: {
              ...data,
              info: {
                ...data.info,
                description: contentPayload.value.content,
                title: 'BetterCloud Developer Portal'
              }
            }
          })
        })
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      // This is the second part of workaround for navigating directly to doc headers. Upon mount, it may receive a hash that tells this component
      // where the user is trying to land.
      const { location } = this.props;

      if (location.hash.includes('section') || location.hash.includes('tag')) {
        window.history.pushState({}, null, location.hash);
      }

      this.props.fetchContent()
    },
    componentWillReceiveProps(nextProps) {
      if (this.props.contentKey !== nextProps.contentKey) {
        this.props.fetchContent()
      } else if (this.props.contentPayload !== nextProps.contentPayload && !_.isNil(nextProps.contentPayload.value)) {
        this.props.fetchSwaggerContent()
      }
    },
  }),
  branch(({ swaggerContentPayload }) => swaggerContentPayload.loading, renderComponent(Loading)),
  branch(({ swaggerContentPayload }) => _.isNil(swaggerContentPayload.value), renderComponent(NakedNoToken)),
)(RedocContentfulContainer)
