import _ from 'lodash'
import { selectEntitySlice } from '../state'
import { BUFFERS, STREAMS, SUBSCRIPTIONS } from '../../entities'


export const selectSubscriptionSlice = state => selectEntitySlice(state, SUBSCRIPTIONS)
export const selectSubscriptionById = (state, id) => selectSubscriptionSlice(state).byIds[id]
export const selectSubscriptionsByDestId = (state, destId) =>
  _.values(selectSubscriptionSlice(state).byIds).filter(sub => sub.destId === destId)

const selectEmitterSliceByType = (state, type) => {
  const emitters = selectEntitySlice(state, type)
  const typedEmitter = _.values(emitters.byIds)
  typedEmitter.forEach(em => {
    em.subscriptions = _.keyBy(selectSubscriptionsByDestId(state, em.id), 'srcId')
    if (em.meta) {
      em.meta.sources = {}
    }
    _.keys(em.subscriptions).forEach(
      subSrcId => (em.meta.sources[subSrcId] = !!em.subscriptions[subSrcId].enabled),
    )
  })
  return {
    ...emitters,
    byIds: _.keyBy(typedEmitter, 'id'),
    ids: typedEmitter.map(w => w.id),
  }
}

export const selectBufferSlice = state => selectEmitterSliceByType(state, BUFFERS)
export const selectBuffers = state => selectBufferSlice(state).byIds
export const selectBufferById = (state, id) => selectBuffers(state)[id]
export const selectDefaultBuffer = state =>
  _.head(_.values(selectBuffers(state)).filter(b => b.meta.isDefault))

export const selectStreamsSlice = state => selectEntitySlice(state, STREAMS)
export const selectStreams = state => selectStreamsSlice(state).byIds

export const selectActiveApiKey = state => _.get(state, 'apiKeys.active', null)

export * from '../state'
