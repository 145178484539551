import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { executePollingTest } from '../../../rdx/actions'

import { compose, setPropTypes, withState } from 'recompose'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import MuiBufferResults from './MuiBufferResults'
import MuiBuffersPagingStrategyForm from './MuiBuffersPagingStrategyForm'

const MuiBuffersTestForm = ({ emitter, onTestEmitter, readOnly = true, payload, setPayload }) => {
  return (
    <div className="MuiBuffersTestForm">
      <p>
        Test out querying your event buffers here. Note: data will not be populated until after you
        publish and activate the event buffer and some new data has streamed into it.
      </p>
      <MuiBuffersPagingStrategyForm payload={payload} setPayload={setPayload} />
      <div style={{ padding: '1em' }}>
        <Button
          variant="contained"
          onClick={() => {
            onTestEmitter(emitter, payload)
          }}
        >{`Test ${_.capitalize('event buffer')}`}</Button>
        <MuiBufferResults emitterId={emitter.id} />
      </div>
    </div>
  )
}

export default compose(
  setPropTypes({
    emitter: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    readOnly: PropTypes.bool,
  }),
  withState('payload', 'setPayload', {
    strategy: 'PAGE_SIZE',
    page: 0,
    size: 100,
  }),
  connect(
    state => ({}),
    dispatch => ({
      onTestEmitter: (emitter, payload) => {
        dispatch(executePollingTest(emitter.id, payload))
      },
    }),
  ),
)(MuiBuffersTestForm)
