import _ from 'lodash'

// TODO: change contract and delete this file
export default {
  inbound(webhook) {
    const { rateLimit: value, rateLimitId: bucket } = webhook.meta
    let strategy = 'NONE'
    if (value > 0) {
      strategy = !!bucket ? 'SHARED' : 'INDIVIDUAL'
    }
    webhook.meta.rateLimitMeta = {
      strategy,
      value,
      bucket,
    }
  },
  outbound(webhook) {
    const { value: rateLimit, bucket: rateLimitId } = webhook.meta.rateLimitMeta
    let subType = 'SCRIPTING'

    if (webhook.type === 'WEBHOOK') {
      subType = _.values(webhook.meta.sources).includes(true) ? 'PUSH_EVENT' : 'ACTION'
    }

    webhook.meta = {
      ...webhook.meta,
      rateLimit: parseInt(rateLimit),
      rateLimitId,
      subType: subType
    }
  },
}
