import React from 'react'
import _ from 'lodash'
import graphQLFetcher from './graphQLFetcher'
import { selectActiveApiKey, selectGqlQuery } from '../../rdx/selectors'
import { updateGqlQuery } from '../../rdx/actions'

import { connect } from 'react-redux'

import GraphiQL from 'graphiql'
import { Dropdown } from 'primereact/components/dropdown/Dropdown'

import * as gqlExamplesMap from './examples'

const EXAMPLE_QUERIES = _.values(gqlExamplesMap)

const GraphiqlPlayground = ({ query, apiKey, onEditQuery }) => (
  <div className="GraphiqlPage">
    <div style={{ textAlign: 'right' }}>
      <Dropdown
        options={EXAMPLE_QUERIES}
        onChange={({ value }) => onEditQuery(value)}
        placeholder="Not sure where to start? Pick an example!"
        style={{ width: '40vw' }}
      />
    </div>
    <div style={{ height: '66vh' }}>
      <GraphiQL query={query} fetcher={graphQLFetcher(apiKey)} onEditQuery={onEditQuery}>
        <GraphiQL.Logo>BetterCloud - GraphQL</GraphiQL.Logo>
      </GraphiQL>
    </div>
  </div>
)

export default connect(
  state => ({
    query: selectGqlQuery(state),
    apiKey: selectActiveApiKey(state).value,
  }),
  dispatch => ({
    onEditQuery: query => dispatch(updateGqlQuery(query)),
  }),
)(GraphiqlPlayground)
