import React from 'react'
import { selectSettingCodeLanguage } from '../../rdx/selectors'
import { setCodeLanguage } from '../../rdx/actions'
import { CODE_LANGUAGES } from '../../entities'

import { connect } from 'react-redux'

import TextField from '@material-ui/core/TextField'

const ManageLanguage = ({ language, setLanguage }) => (
  <div className="ManageLanguage">
    <h1>Manage Coding Language</h1>
    <TextField
      select
      value={language}
      onChange={e => setLanguage(e.target.value)}
      style={{ width: '100%' }}
    >
      {CODE_LANGUAGES.map(lang => (
        <option value={lang.value} key={lang.value}>
          {lang.label}
        </option>
      ))}
    </TextField>
  </div>
)

export default connect(
  state => ({
    language: selectSettingCodeLanguage(state),
  }),
  dispatch => ({
    setLanguage: newLang => dispatch(setCodeLanguage(newLang)),
  }),
)(ManageLanguage)
