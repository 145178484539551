import React from 'react'
import _ from 'lodash'
import urls from '../../../urls'

import { Link } from 'react-router-dom'

const NAV_SECTIONS = [
  {
    title: 'Get Started',
    links: [
      { to: urls.content('quickstart'), text: 'Quick Start', icon: 'bolt', anon: true },
      { to: urls.settings.home(), text: 'Dev Settings', icon: 'cogs', anon: true },
    ],
    anon: true,
  },
  {
    title: 'Data Graph',
    links: [
      { to: urls.reporting.explore(), text: 'Explorer', icon: 'globe' },
      { to: urls.reporting.graph(), text: 'Visualization', icon: 'desktop' },
    ],
  },
  {
    title: 'Advanced',
    links: [
      { to: urls.buffers.home(), text: 'Event Streams', icon: 'code-fork' },
    ],
  },
]

const styles = {
  sectionHeader: {
    color: '#9d9a95',
    marginTop: '1.5em',
  },
  sectionLinkContainer: {
    marginLeft: '1em',
    marginTop: '0.5em',
  },
  sectionLink: {
    color: '#294658',
  },
}

const NavSectionLink = ({ icon, text, to }) => (
  <div style={styles.sectionLinkContainer}>
    <Link to={to} style={styles.sectionLink}>
      <i className={`fa fa-${icon}`} /> {text}
    </Link>
  </div>
)

const NavSection = ({ isAnon, title, links }) => (
  <div className="NavSection">
    <div style={styles.sectionHeader}>{_.upperCase(title)}</div>
    {links
      .filter(link => !isAnon || link.anon)
      .map((link, i) => <NavSectionLink {...link} key={i} />)}
  </div>
)

const LeftNav = ({ isAnon }) => (
  <div className="LeftNav">
    {NAV_SECTIONS.filter(section => !isAnon || section.anon).map((section, i) => (
      <NavSection isAnon={isAnon} title={section.title} links={section.links} key={i} />
    ))}
  </div>
)

export default LeftNav
