import { SECRETKEYS } from '../../entities'

export const SAVE_ENTITY = 'SAVE_ENTITY'
export const HANDLE_SAVE_SUCCESS = 'HANDLE_SAVE_ENTITY_SUCCESS'
export const HANDLE_SAVE_FAILURE = 'HANDLE_SAVE_ENTITY_FAILURE'

export const FETCH_ENTITY = 'FETCH_ENTITY'
export const HANDLE_FETCH_ENTITY_SUCCESS = 'HANDLE_FETCH_ENTITY_SUCCESS'
export const HANDLE_FETCH_ENTITY_FAILURE = 'HANDLE_FETCH_ENTITY_FAILURE'

export const FETCH_ENTITIES = 'FETCH_ENTITIES'
export const HANDLE_FETCH_ENTITIES_SUCCESS = 'HANDLE_FETCH_ENTITIES_SUCCESS'
export const HANDLE_FETCH_ENTITIES_FAILURE = 'HANDLE_FETCH_ENTITIES_FAILURE'

export const FETCH_SECRET_KEYS = 'FETCH_SECRET_KEYS'
export const HANDLE_FETCH_SECRET_KEYS_SUCCESS = 'HANDLE_FETCH_SECRET_KEYS_SUCCESS'
export const HANDLE_FETCH_SECRET_KEYS_FAILURE = 'HANDLE_FETCH_SECRET_KEYS_FAILURE'

export const DELETE_ENTITY = 'DELETE_ENTITY'
export const HANDLE_DELETE_SUCCESS = 'HANDLE_DELETE_ENTITY_SUCCESS'
export const HANDLE_DELETE_FAILURE = 'HANDLE_DELETE_ENTITY_FAILURE'

export const SELECT_ENTITY = 'SELECT_ENTITY'

/*
 * SAVE
 */
export const saveEntity = (entityKey, path, entity, silent) => ({
  type: SAVE_ENTITY,
  entityKey,
  path,
  entity,
  silent,
})
export const handleSaveSuccess = (entityKey, entity) => ({
  type: HANDLE_SAVE_SUCCESS,
  entityKey,
  entity,
})
export const handleSaveFailure = (entityKey, error) => ({
  type: HANDLE_SAVE_FAILURE,
  entityKey,
  error,
})

/*
 * FETCH ENTITIY
 */
export const fetchEntity = (entityKey, path, id) => ({
  type: FETCH_ENTITY,
  entityKey,
  path,
  id,
})
export const handleFetchEntitySuccess = (entityKey, id, entity) => ({
  type: HANDLE_FETCH_ENTITY_SUCCESS,
  entityKey,
  id,
  entity,
})
export const handleFetchEntityFailure = (entityKey, error) => ({
  type: HANDLE_FETCH_ENTITY_FAILURE,
  entityKey,
  error,
})

/*
 * FETCH ENTITIES
 */
export const fetchEntities = (entityKey, path, paging = {}, query) => ({
  type: FETCH_ENTITIES,
  entityKey,
  path,
  paging,
  query,
})
export const handleFetchEntitiesSuccess = (entityKey, byIds, ids, pagination) => ({
  type: HANDLE_FETCH_ENTITIES_SUCCESS,
  entityKey,
  byIds,
  ids,
  pagination,
})
export const handleFetchEntitiesFailure = (entityKey, error) => ({
  type: HANDLE_FETCH_ENTITIES_FAILURE,
  entityKey,
  error,
})

/*
 * FETCH SECRET KEYS
 */
export const fetchKeys = (entityKey, path, paging) => ({
  type: FETCH_SECRET_KEYS,
  entityKey,
  path,
  paging
})
export const handleFetchSecretKeysSuccess = (entityKey, keys) => ({
  type: HANDLE_FETCH_SECRET_KEYS_SUCCESS,
  entityKey,
  keys,
})
export const handleFetchSecretKeysFailure = (entityKey, error) => ({
  type: HANDLE_FETCH_SECRET_KEYS_FAILURE,
  entityKey,
  error,
})

/*
 * DELETE
 */
export const deleteEntity = (entityKey, path, id) => ({
  type: DELETE_ENTITY,
  entityKey,
  path,
  id,
})
export const handleDeleteSuccess = (entityKey, id) => ({
  type: HANDLE_DELETE_SUCCESS,
  entityKey,
  id,
})
export const handleDeleteFailure = (entityKey, error) => ({
  type: HANDLE_DELETE_FAILURE,
  entityKey,
  error,
})

export const selectEntities = (entityKey, ids) => ({
  type: SELECT_ENTITY,
  entityKey,
  ids,
})

/*
 * GENERATOR
 */
export const entityGenerator = (entityKey, path, implicitQuery) => ({
  saveEntity: (entity, silent = false) => saveEntity(entityKey, path, entity, silent),
  fetchEntity: id => fetchEntity(entityKey, path, id),
  fetchEntities: (paging = { page: 0, limit: 10 }) =>
    fetchEntities(entityKey, path, paging, implicitQuery),
  fetchEntitiesWithQuery: query =>
    fetchEntities(
      entityKey,
      path,
      { page: 0, limit: 10 },
      [implicitQuery, query].filter(Boolean).join(';'),
    ),
  fetchKeys: (paging = { page: 0, limit: 1000}) => fetchKeys(SECRETKEYS, path, paging),
  deleteEntity: id => deleteEntity(entityKey, path, id),
  selectEntities: ids => selectEntities(entityKey, ids),
})
