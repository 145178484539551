export const EXECUTE_EMITTER_TEST = 'EXECUTE_EMITTER_TEST'
export const EXECUTE_POLLING_TEST = 'EXECUTE_POLLING_TEST'
export const EXECUTE_EMITTER_TEST_SUCCESS = 'EXECUTE_EMITTER_TEST_SUCCESS'
export const EXECUTE_EMITTER_TEST_FAILURE = 'EXECUTE_EMITTER_TEST_FAILURE'

export const executeEmitterTest = (emitterId, payload, exeType) => ({
  type: EXECUTE_EMITTER_TEST,
  emitterId,
  payload,
  exeType,
})

// TODO:
export const executePollingTest = (emitterId, payload) => ({
  type: EXECUTE_POLLING_TEST,
  emitterId,
  payload,
})

export const executeEmitterTestSuccess = (emitterId, req, res) => ({
  type: EXECUTE_EMITTER_TEST_SUCCESS,
  emitterId,
  req,
  res,
})
