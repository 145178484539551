import React from 'react'

import GraphiqlPlayground from './GraphiqlPlayground'
import GqlSnippets from './GqlSnippets'

const MuiExporePage = () => (
  <div className="MuiExporePage">
    <GraphiqlPlayground />
    <GqlSnippets />
  </div>
)

export default MuiExporePage
