let baseUrl = window.location.href.replace(/(https?:\/\/[^/]+).*/, '$1').replace(/\/$/, '')
let fsOrg = null;

const HOLDING_BASE = 'https://api-holding.devbettercloud.com'
const STAGING_BASE = 'https://api-staging.devbettercloud.com'
const PRODUCTION_BASE = 'https://api.bettercloud.com';

switch (baseUrl) {
  case 'http://localhost:3000': {
    baseUrl = 'http://localhost:17003'
    baseUrl = HOLDING_BASE
    fsOrg = '10X2';
    break
  }
  case 'https://bc-dev-portal.surge.sh':
  case 'https://developer-holding.devbettercloud.com': {
    baseUrl = HOLDING_BASE
    fsOrg = '10X2'
    break
  }
  case 'https://developer-staging.devbettercloud.com': {
    baseUrl = STAGING_BASE
    fsOrg = '10X2'
    break
  }
  case 'https://developer.bettercloud.com': {
    baseUrl = PRODUCTION_BASE
    fsOrg = '104r'
    break
  }
  default:
}

export default {
  baseUrl,
  fsOrg
}
