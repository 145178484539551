export const emptyGroups = {
  label: 'Empty Groups',
  value: `# Empty Groups
{
  groups(filter: {
    memberCount: {
      eq: 0
    }
  }) {
    id
    displayValue
    memberCount
  }
}
`,
}
