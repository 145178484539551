import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'
import {
  selectResultsByEmitterId,
  selectSettingCodeLanguage,
  selectActiveApiKey,
} from '../../../rdx/selectors'
import { getBody } from '../../common/testing/testingUtils'
import { toastr } from 'react-redux-toastr'

import { compose, setPropTypes } from 'recompose'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner'

function toCurl(url) {
  return `
curl -XGET '${url}' \\
  -H 'Content-Type: application/json' \\
  -H 'Authorization: {apiKey}'
`
}

function toJs(url) {
  return `
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

fetch('${url}', {
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
    Authorization: '{apiKey}'
  }
}).then(response => {
  return response.json()
}).then(({ data }) => {
  console.log(data)
})
.catch(e => {
  console.error('Failed to execute query', e)
})
`
}

function toPython(url) {
  return `
# http://docs.python-requests.org/en/latest/

import requests
url = '${url}'
headers = {
  "Content-Type": "application/json",
  "Authorization": "{apiKey}"
}
data = requests.post(url, headers=headers).json()
print data
`
}

function toSnippet(codeLanguage, url, apiKey) {
  switch (codeLanguage) {
    case 'js':
      return toJs(url, apiKey)
    case 'python':
      return toPython(url, apiKey)
    case 'curl':
    default:
  }
  return toCurl(url, apiKey)
}

const BufferResults = ({ result, codeLanguage, apiKey }) => {
  if (_.isNil(result)) return ''
  if (result.loading)
    return (
      <div className="text-center">
        <ProgressSpinner animationDuration=".5s" />
      </div>
    )
  const { req, res } = result
  const snippet = toSnippet(codeLanguage, req.url, apiKey)
  const isSuccess = Math.floor(res.statusCode / 100) === 2
  return (
    <div className="BufferResults" style={{ marginTop: '1em' }}>
      <h2>Request</h2>
      <div className="text-right">
        <CopyToClipboard text={req.url} onCopy={() => toastr.success('Copied URL')}>
          <Button color="primary">
            <i className="fa fa-copy" />&nbsp;Copy URL
          </Button>
        </CopyToClipboard>
      </div>
      <div className="pl-3">
        <code>
          <pre>
            <code>{req.url}</code>
          </pre>
        </code>
      </div>
      <div className="text-right">
        <CopyToClipboard text={snippet} onCopy={() => toastr.success('Copied code snippet')}>
          <Button color="primary">
            <i className="fa fa-copy" />&nbsp;Copy Snippet
          </Button>
        </CopyToClipboard>
      </div>
      <div className="pl-3">
        <pre>
          <code>{snippet}</code>
        </pre>
      </div>
      <h2>Response</h2>
      <div className="pl-3">
        <div className="BufferResponse">
          <pre
            className={classnames('p-3 text-white text-center', {
              'bg-success': isSuccess,
              'bg-danger': !isSuccess,
            })}
          >
            {res.statusCode} - {res.statusCodeValue}
          </pre>
          <h3>Body:</h3>
          <code>
            <pre>
              <code>{getBody(res.body)}</code>
            </pre>
          </code>
        </div>
      </div>
    </div>
  )
}

export default compose(
  setPropTypes({
    emitterId: PropTypes.string.isRequired,
  }),
  connect((state, { emitterId }) => ({
    result: selectResultsByEmitterId(state, emitterId),
    codeLanguage: selectSettingCodeLanguage(state),
    apiKey: selectActiveApiKey(state).value,
  })),
)(BufferResults)
