import _ from 'lodash'
import {
  FETCH_ENTITY,
  HANDLE_FETCH_ENTITY_SUCCESS,
  HANDLE_SAVE_SUCCESS,
  HANDLE_DELETE_SUCCESS,
  HANDLE_FETCH_ENTITY_FAILURE,
  FETCH_ENTITIES,
  HANDLE_FETCH_ENTITIES_SUCCESS,
  HANDLE_FETCH_ENTITIES_FAILURE,
  SELECT_ENTITY,
} from '../actions'
import { SECRETKEYS } from '../../entities'

const defaultPagination = {
  count: 0,
  page: 0,
  pages: 0,
}

const defaultEntitySlice = {
  initialized: false,
  loading: false,
  byIds: {},
  ids: [],
  selected: {},
  error: null,
  pagination: defaultPagination,
  meta: {},
}

const defaultSecretKeys = {
  initialized: false,
  loading: false,
  keys: new Map(),
}

const selectSlice = state => state.entities

export const selectEntitySlice = (state, key) =>
  _.defaultTo(selectSlice(state)[key], defaultEntitySlice)
export const selectSecretKeysSlice = state => _.defaultTo(selectSlice(state)[SECRETKEYS], defaultSecretKeys)
export const selectEntityData = (state, key) => selectEntitySlice(state, key).byIds

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ENTITIES:
    case FETCH_ENTITY: {
      return {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          loading: true,
        },
      }
    }

    case HANDLE_SAVE_SUCCESS:
    case HANDLE_FETCH_ENTITY_SUCCESS: {
      const newState = {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          initialized: true,
          loading: false,
          error: null,
          meta: action.meta,
        },
      }

      newState[action.entityKey].byIds[action.entity.id] = action.entity
      newState[action.entityKey].ids.push(action.entity.id)
      newState[action.entityKey].selected[action.entity.id] = action.entity

      return newState
    }

    case HANDLE_DELETE_SUCCESS: {
      const newState = {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          initialized: true,
          loading: false,
          error: null,
          meta: action.meta,
        },
      }

      delete newState[action.entityKey].byIds[action.id]
      _.pull(newState[action.entityKey].ids, action.id)

      return newState
    }

    case HANDLE_FETCH_ENTITY_FAILURE: {
      return {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          initialized: true,
          loading: false,
          error: action.error,
          meta: {},
        },
      }
    }

    case HANDLE_FETCH_ENTITIES_SUCCESS: {
      return {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          initialized: true,
          loading: false,
          byIds: action.byIds,
          ids: action.ids,
          pagination: action.pagination,
          error: null,
          meta: action.meta,
        },
      }
    }

    case HANDLE_FETCH_ENTITIES_FAILURE: {
      return {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          initialized: true,
          loading: false,
          byIds: {},
          ids: [],
          pagination: defaultPagination,
          error: action.error,
          meta: {},
        },
      }
    }

    case SELECT_ENTITY: {
      const updatedState = {
        ...state,
        [action.entityKey]: {
          ..._.defaultTo(state[action.entityKey], defaultEntitySlice),
          initialized: true,
          loading: false,
          error: null,
          meta: action.meta,
        },
      }

      action.ids.forEach(id => {
        if (!updatedState[action.entityKey].selected[id]) {
          updatedState[action.entityKey].selected[id] = updatedState[action.entityKey].byIds[id]
        }
      })

      return updatedState
    }

    default:
  }

  return state
}
