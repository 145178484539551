import _ from 'lodash'
import { UPDATE_GQL_QUERY } from '../actions'

const selectSlice = state => _.get(state, 'gql')

export const selectGqlQuery = state => selectSlice(state).query

const defaultState = {
  query: '{}',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_GQL_QUERY: {
      return {
        ...state,
        query: action.query,
      }
    }

    default:
  }
  return state
}
