import React from 'react'
import _ from 'lodash'

import { compose, withHandlers, withState } from 'recompose'

import Button from '@material-ui/core/Button'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const DEFAULT_CHILD = <MoreHoriz />

const RowActionsButton = ({
  menuAnchor,
  options,
  onSelectOption,
  setMenuAnchor,
  buttonProps,
  buttonStyle,
  children = DEFAULT_CHILD,
}) => (
  <span>
    <Button
      className="RowActionsButton"
      onClick={e => setMenuAnchor(e.currentTarget)}
      {...buttonProps}
    >
      {children}
    </Button>
    <Menu
      id="emitter-list-action-menu"
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      onClose={() => onSelectOption()}
    >
      {options.map((op, i) => (
        <MenuItem onClick={() => onSelectOption(op.handler)} key={i}>
          {op.label}
        </MenuItem>
      ))}
    </Menu>
  </span>
)

export default compose(
  withState('menuAnchor', 'setMenuAnchor', null),
  withHandlers({
    onSelectOption: ({ setMenuAnchor }) => handler => {
      if (_.isFunction(handler)) {
        handler()
      }
      setMenuAnchor(null)
    },
  }),
)(RowActionsButton)
