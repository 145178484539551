import _ from 'lodash'
import {
  EXECUTE_EMITTER_TEST,
  EXECUTE_POLLING_TEST,
  EXECUTE_EMITTER_TEST_SUCCESS,
} from '../../actions'

const selectSlice = state => _.get(state, 'emitters.testing')

export const selectResultsByEmitterId = (state, emitterId) => selectSlice(state)[emitterId]

const getDefaultResult = () => ({
  loading: true,
  req: {},
  res: {},
})

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case EXECUTE_POLLING_TEST:
    case EXECUTE_EMITTER_TEST: {
      return {
        ...state,
        [action.emitterId]: {
          ...getDefaultResult(),
        },
      }
    }

    case EXECUTE_EMITTER_TEST_SUCCESS: {
      const { emitterId, res, req } = action
      return {
        ...state,
        [emitterId]: {
          loading: false,
          req,
          res,
        },
      }
    }

    default:
  }
  return state
}
