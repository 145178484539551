import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'
import MaterialLayout from './components/common/layout/MaterialLayout'
import ContentfulPage from './components/common/ContentfulPage'

import DeveloperSettings from './components/settings/DeveloperSettings'
import ManageTokens from './components/settings/ManageTokens'
import ManageLanguage from './components/settings/ManageLanguage'

import NakedNotFound from './components/common/NakedNotFound'
import NakedNoToken from './components/common/NakedNoToken'
import RedocContentfulContainer from './components/common/RedocContentfulContainer';

const MuiApp = () => (
  <div className="MuiApp">
    <Switch>
      <Route exact path="/docs" render={() => <RedocContentfulContainer contentKey="docs"/>}/>
      <MaterialLayout isAnon={true}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/p/quickstart"/>} />

          <Route path="/buffers" component={NakedNoToken} />

          <Route exact path="/settings" component={DeveloperSettings} />
          <Route exact path="/settings/tokens" component={ManageTokens} />
          <Route exact path="/settings/codeLanguage" component={ManageLanguage} />

          <Route path="/reporting" component={NakedNoToken} />

          <Route exact path="/p/:contentKey" component={ContentfulPage} />

          <Route component={NakedNotFound} />
        </Switch>
      </MaterialLayout>
    </Switch>
  </div>
)

export default MuiApp
