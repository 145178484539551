export const BUFFERS = 'BUFFERS'
export const SCRIPTS = 'SCRIPTS'
export const SECRETKEYS = 'SECRETKEY'
export const STREAMS = 'STREAMS'
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'
export const WEBHOOKHISTORY = 'WEBHOOKHISTORY'

export const CODE_LANGUAGES = [
  { label: 'Shell (curl)', value: 'curl' },
  { label: 'Javascript', value: 'js' },
  { label: 'Python', value: 'python' },
]

export const SUBSCRIPTION_SOURCES = [
  {
    id: '36629c0d-26be-4eff-9d1c-915ed63d9465',
    key: 'custom payload',
    description: 'Exposes this as an action in Action Engine and Workflows',
    props: { checked: true, disabled: true },
    testing: { customPayload: true },
    bufferProps: { enabled: false },
  },
  {
    id: 'dd07cf14-ed02-4079-a8f3-41b5b6895606',
    key: 'auditlog.history',
    description: 'Occurs whenever an audit log is written',
    props: {},
    testing: {},
    bufferProps: {},
  },
  {
    id: '5b6fdb2a-cf17-4ae3-856f-e13ffd3f4265',
    key: 'alert.trigger',
    description: 'Occurs whenever an alert is triggered',
    props: {},
    testing: {},
    bufferProps: {},
  },
  {
    id: '914e4363-b527-4c7d-a75a-a1f7313bf7cb',
    key: 'workflow.status',
    description: 'Occurs whenever a workflow changes status',
    props: {},
    testing: {},
    bufferProps: {},
  },
  {
    id: '5c9b4f42-1894-48e7-87e2-abe2b512f8e3',
    key: 'actionengine.history',
    description: 'Occurs whenever an action has completed',
    props: {},
    testing: {},
    bufferProps: {},
  },
]
