export const superAdmins = {
  label: 'Super Admin Report',
  value: `# Super Admin Report
{
  roles(filter: {displayValue: {eq: "Super Admin"}}) {
    displayValue
    members {
      member {
        accounts {
          displayValue
          admin
          connector {
            provider {
              displayValue
            }
          }
        }
      }
    }
  }
}
`,
}
