import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import NavBar from './NavBar'
import LeftNav from './LeftNav'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#153347',
    },
  },
})

const styles = {
  verticalGrid: {
    minHeight: '100vh',
    display: 'grid',
    gridTemplate: '64px 1fr / 100%',
  },
  horizontalGrid: {
    display: 'grid',
    gridTemplate: '1fr / 250px 1fr',
  },
  leftNavContainer: {
    backgroundColor: '#e9ecf1',
    padding: '1em',
  },
  contentContainer: {
    backgroundColor: '#f9f9fa',
    padding: '1em',
    maxWidth: '75vw',
  },
}

const MaterialLayout = ({ isAnon = false, children }) => (
  <MuiThemeProvider theme={theme}>
    <div className="MaterialLayout mui" style={styles.verticalGrid}>
      <NavBar />
      <div style={styles.horizontalGrid}>
        <div style={styles.leftNavContainer}>
          <LeftNav isAnon={isAnon} />
        </div>
        <div style={styles.contentContainer}>{children}</div>
      </div>
    </div>
  </MuiThemeProvider>
)

export default MaterialLayout
