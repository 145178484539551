import React from 'react'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'

const PAGE_SIZE = 'PAGE_SIZE'
const LIMIT_OFFSET = 'LIMIT_OFFSET'
const CURSOR = 'CURSOR'
const SCROLL_SESSION = 'SCROLL_SESSION'

const PAGING_STRATEGIES = [PAGE_SIZE, LIMIT_OFFSET, CURSOR, SCROLL_SESSION].map(value => ({
  label: value,
  value,
}))

const PAGING_STRATEGY_DEFAULTS = {
  PAGE_SIZE: { page: 0, size: 100 },
  LIMIT_OFFSET: { offset: 0, limit: 100 },
  CURSOR: { cursor: null },
  SCROLL_SESSION: { session: null },
}

const PageSizeForm = ({ payload, setPayload }) => (
  <div className="ImmediateRetryForm">
    <p>
      Query through your events in order by specifying how many records you want (size) and what 0
      indexed page of results you want (page).
    </p>
    <Grid container spacing={16}>
      <Grid item md={6}>
        <TextField
          label="Page Number"
          value={`${payload.page}`}
          onChange={e =>
            setPayload({
              ...payload,
              page: e.target.value,
            })
          }
          style={{ width: '100%' }}
          type="number"
          min="0"
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          label="Page Size"
          value={`${payload.size}`}
          onChange={e => {
            setPayload({
              ...payload,
              size: e.target.value,
            })
          }}
          style={{ width: '100%' }}
          type="number"
          min="1"
          max="100"
        />
      </Grid>
    </Grid>
  </div>
)

const LimitOffsetForm = ({ payload, setPayload }) => (
  <div className="ImmediateRetryForm">
    <p>
      Query through your events in order by specifying how many records you want (limit) and how
      many records you want to skip before your result set (offset).
    </p>
    <Grid container spacing={16}>
      <Grid item md={6}>
        <TextField
          label="Limit"
          value={`${payload.limit}`}
          onChange={e => {
            setPayload({
              ...payload,
              limit: e.target.value,
            })
          }}
          style={{ width: '100%' }}
          type="number"
          min="1"
          max="100"
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          label="Offset"
          value={`${payload.offset}`}
          onChange={e => {
            setPayload({
              ...payload,
              offset: e.target.value,
            })
          }}
          style={{ width: '100%' }}
          type="number"
          min="0"
        />
      </Grid>
    </Grid>
  </div>
)

const CursorForm = ({ payload, setPayload }) => (
  <div className="LinearBackoffRetryForm">
    <p>
      Query through your events in order by using a cursor. The first cursor should be null or not
      supplied. After that, if there are multiple pages of results, we'll provide you with a cursor
      for the next query.
    </p>
    <TextField
      label="Title"
      value={payload.cursor}
      onChange={e => {
        setPayload({
          ...payload,
          cursor: e.target.value,
        })
      }}
      style={{ width: '100%' }}
    />
  </div>
)

const ScrollSessionForm = ({ payload, setPayload }) => (
  <div className="LinearBackoffRetryForm">
    <p>
      Paging can be tricky. Let us do the heavy lifting. Define your own scroll session, and we'll
      keep track of where you are looking through your buffer for this session. Multiple calls to
      the same scroll session will yield different results, so if you need 2 different scripts to
      read through your events, they should each have their own unique scroll session.
    </p>
    <TextField
      label="Scroll Session"
      value={payload.session}
      onChange={e => {
        setPayload({
          ...payload,
          session: e.target.value,
        })
      }}
      style={{ width: '100%' }}
    />
  </div>
)

function renderSubForm(payload, setPayload) {
  const strategy = payload.strategy
  let SubForm = () => <div />
  switch (strategy) {
    case PAGE_SIZE:
      SubForm = PageSizeForm
      break
    case LIMIT_OFFSET:
      SubForm = LimitOffsetForm
      break
    case CURSOR:
      SubForm = CursorForm
      break
    case SCROLL_SESSION:
      SubForm = ScrollSessionForm
      break
    default:
  }
  return <SubForm payload={payload} setPayload={setPayload} />
}

const BuffersPagingStrategyForm = ({ payload, setPayload }) => (
  <div className="WebhookRetryStrategyForm">
    <h3>Paging Strategy</h3>
    <TextField
      select
      value={payload.strategy}
      onChange={e => {
        payload = {
          strategy: e.target.value,
          ...PAGING_STRATEGY_DEFAULTS[e.target.value],
        }
        setPayload(payload)
      }}
      style={{ width: '100%' }}
    >
      {PAGING_STRATEGIES.map((strat, i) => (
        <MenuItem value={strat.value} key={i}>
          {strat.label}
        </MenuItem>
      ))}
    </TextField>
    {renderSubForm(payload, setPayload)}
  </div>
)

export default BuffersPagingStrategyForm
