import React from 'react'
import urls from '../../urls'

import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

const styles = {
  contentContainer: {
    textAlign: 'center',
  },
}

const NakedNoToken = () => (
  <div className="NakedNoToken" style={styles.contentContainer}>
    <Typography variant="headline">No valid token!</Typography>
    <Typography variant="caption">
      Checkout our <Link to={urls.content('quickstart')}>quickstart</Link> page to find out how to
      setup a valid token.
    </Typography>
  </div>
)

export default NakedNoToken
